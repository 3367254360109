import React from 'react';
import { MapContainer, TileLayer, Polyline } from 'react-leaflet';
import { decode } from 'google-polyline';
import logs from './logs/rnav.json';
import './App.css';

function App() {
    const polylines = logs.map((item) => {
        return <Polyline positions={decode(item)} key={Math.random()} weight={1.5} />;
    });
    return (
        <div className="app">
            <MapContainer center={[54.10214,-1.03272]} zoom={9}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {polylines}
            </MapContainer>
        </div>
    );
}

export default App;
